<template>
  <div id="app">
    <component :is="loadTemplate"
               v-if="loadTemplate"></component>
  </div>
</template>

<script>
import TemplateDefault from '@/layouts/TemplateDefault';
import TemplateRetail from '@/layouts/TemplateRetail';
import { mapActions, mapGetters } from 'vuex';
import { pusher } from '@/plugins';

export default {
  name: 'App',
  components: {
    TemplateDefault,
    TemplateRetail,
  },
  data() {
    return {
      loadTemplate: '',
    };
  },
  methods: {
    ...mapActions(['getSettings', 'setOffline']),
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    window.addEventListener('offline', () => {
      this.setOffline(true);
    });
  },
  async created() {
    await this.getSettings();
    this.loadTemplate = this.config.route === 'retail' ? 'TemplateRetail' : 'TemplateDefault';
    pusher.init();
  },
};
</script>

<style lang="scss">
@import './style/main.scss';
html,
body,
p {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
html {
  width: 100vw;
  font-size: calc(10px + 0.8vw);
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'BoostNfont';
  src: url('/fonts/BoostNfont.eot');
  src: url('/fonts/BoostNfont.eot') format('embedded-opentype'),
    url('/fonts/BoostNfont.ttf') format('truetype'),
    url('/fonts/BoostNfont.woff') format('woff'),
    url('/fonts/BoostNfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  font-family: 'BoostNfont';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  size-adjust: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
