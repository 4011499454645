import { filter } from 'lodash';

const assetsList = [
  { type: 'image', location: 'retail', path: '/assets/retail/big-win-sprite.png' },
  { type: 'image', location: 'retail', path: '/assets/retail/jackpot-sprite.png' },
  { type: 'image', location: 'retail', path: '/assets/retail/retail-logo.png' },
  { type: 'image', location: 'retail', path: '/assets/retail/retail-top-wins-placeholder.png' },
  { type: 'image', location: 'retail', path: '/assets/retail/rpi-retail-header.jpg' },
  { type: 'image', location: 'retail', path: '/assets/retail/top-win-sprite.png' },
];

export default {
  loadAssets(location) {
    const promises = [];
    const assets = filter(assetsList, { location });
    assets.forEach((asset) => {
      const promise = new Promise((resolve, reject) => {
        let resource;

        if (asset.type === 'image') {
          resource = new Image();
          resource.src = asset.path;

          resource.onload = resolve;
          resource.onerror = reject;
        }

        // reject after 30 seconds and continue without asset
        setTimeout(reject, 30000);
      });
      promises.push(promise);
    });

    return Promise.allSettled(promises);
  },
};
