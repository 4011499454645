import { createRouter, createWebHistory } from 'vue-router';

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/badge',
      name: 'badge',
      component: () => import('@/pages/Badge'),
    },
    {
      path: '/',
      name: 'modules',
      component: () => import('@/pages/Modules'),
    },
    {
      path: '/retail',
      name: 'retail',
      component: () => import('@/pages/Retail'),
    },
  ],
});
