import {
  cloneDeep,
  find,
  isEmpty,
  isEqual,
  remove,
  some,
  sortBy,
} from 'lodash';
import { parseUndefined, swapTickets } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_SETTINGS](state, payload) {
    state.settings = payload.data;
  },
  [types.UPDATE_TENANT_ID](state, payload) {
    state.config.tenantId = payload;
  },
  [types.SET_BOOST_STATE](state, payload) {
    state.boostState = payload;
  },
  [types.UPDATE_BOOST_STATE](state, payload) {
    state.boostState[payload.prop] = payload.value;
  },
  [types.SET_NAVIGATION](state, payload) {
    state.navigation = payload;
  },
  [types.UPDATE_NAVIGATION](state, payload) {
    state.navigation = { ...state.navigation, ...payload };
  },
  [types.SET_PROFILE](state, payload) {
    state.profileData = payload.data;
  },
  [types.SET_NEW_LEVEL_ANIMATION_STATE](state, payload) {
    state.newLevelAnimationState = payload;
  },
  [types.SET_OTHER_PLAYER_PROFILE](state, payload) {
    state.otherPlayerProfileData = payload.data;
  },
  [types.SET_OTHER_PLAYER_ID](state, payload) {
    state.otherPlayerId = payload;
  },
  [types.SET_FEED](state, payload) {
    state.feedData = payload ? swapTickets(payload.data) : payload;
  },
  [types.DELETE_OTHER_PLAYER_PROFILE_DATA](state) {
    state.otherPlayerProfileData = '';
  },
  [types.SET_TOPWINS](state, payload) {
    if (!isEqual(state.topWinsData, payload.data)) {
      state.topWinsData = payload.data;
    }
  },
  [types.TOGGLE_NEW_LEVEL_POPUP](state, payload) {
    state.newLevelAnimationState = payload;
  },
  [types.SET_ERROR_DETAILS](state, payload) {
    state.errorDetails = payload || true;
  },
  [types.CLOSE_ERROR](state) {
    state.errorDetails = '';
  },
  [types.TOGGLE_LOADER](state, payload) {
    state.activeLoader = payload;
  },
  [types.SET_MODULES_VISIBILITY](state, payload) {
    state.visibleModules = payload;
  },
  [types.UPDATE_PROGRESS_BAR](state, payload) {
    state.progressBarPercentage = parseUndefined(payload) ? payload : 0;
  },
  [types.UPDATE_STARS_PROGRESS_BAR](state, payload) {
    state.starsProgressBarPercentage = payload;
  },
  [types.SET_NEW_LEVEL_NAME](state, payload) {
    state.newLevelName = payload;
  },
  [types.UPDATE_FEED](state) {
    state.streamInterval = setInterval(() => {
      if (state.ticketStream.length) {
        const newTickets = [...state.feedData];
        const newTicket = state.ticketStream.shift();

        if (!some(newTickets, ['id', newTicket.id])) {
          newTickets.unshift(newTicket);
          if (newTickets.length > state.boostState.feedSize) newTickets.pop();

          state.feedData = swapTickets(newTickets);
        }
      }
      if (isEmpty(state.ticketStream)) clearInterval(state.streamInterval);
    }, 500);
  },
  [types.POPULATE_STREAM](state, payload) {
    state.ticketStream.push(payload);
  },
  [types.UPDATE_PROFILE](state, payload) {
    state.profileData[payload.type] = payload.data;
  },
  [types.SET_NEW_PLAYER](state, payload) {
    state.newPlayer = payload;
  },
  [types.REMOVE_TICKET](state, payload) {
    const feedTicketToRemove = find(state.feedData, ['id', payload.id]);
    const streamTicketToRemove = find(state.ticketStream, ['id', payload.id]);

    if (feedTicketToRemove) remove(state.feedData, feedTicketToRemove);
    if (streamTicketToRemove) remove(state.ticketStream, feedTicketToRemove);
  },
  [types.SET_OFFLINE](state, payload) {
    state.isOffline = payload;
  },
  [types.CLEAR_STREAM](state) {
    clearInterval(state.streamInterval);
    state.ticketStream.length = 0;
  },
  [types.SET_ACTIVE_TOURNAMENTS](state, payload) {
    state.activeTournaments = sortBy(payload.data, (tournament) => new Date(tournament.startTime));
  },
  [types.SET_FINISHED_TOURNAMENTS](state, payload) {
    state.finishedTournaments = sortBy(payload.data, (tournament) => -new Date(tournament.endTime));
  },
  [types.SET_TOURNAMENT_DETAILS](state, payload) {
    state.tournamentDetails = payload.data;
  },
  [types.SET_TOURNAMENT_LEADERBOARD](state, payload) {
    if (state.tournamentLeaderboard) {
      state.tournamentLeaderboard.pagination = payload.pagination;
      state.tournamentLeaderboard.data.push(...payload.data);
    } else {
      state.tournamentLeaderboard = payload;
    }
  },
  [types.UPDATE_TOURNAMENT_LEADERBOARD](state, payload) {
    // Temporary solution for active bug - It's not possible to fetch more than
    // 100 items (players) so we will update only 100 first
    if (state.tournamentLeaderboard.data.length <= payload.data.length) {
      // If current leaderboard is smaller or equal to refreshed leaderboard (<= 100) - just swap
      state.tournamentLeaderboard.data = payload.data;
    } else {
      // If current leaderboard is larger than refreshed leaderboard - only swap
      // positions that are received from response
      const currentLeaderboard = cloneDeep(state.tournamentLeaderboard.data);
      currentLeaderboard.splice(0, payload.data.length, ...payload.data);
      state.tournamentLeaderboard.data = currentLeaderboard;
    }
  },
  [types.CLEAR_TOURNAMENT_LEADERBOARD](state) {
    state.tournamentLeaderboard = '';
  },
  [types.SET_PLAYER_LEADERBOARD](state, payload) {
    state.playerLeaderboard = payload.data;
  },
  [types.EXPAND_LEADERBOARD](state, payload) {
    state.tournamentLeaderboard.data = payload.data;
    state.tournamentLeaderboard.pagination.page = payload.page;
  },
  [types.FORMAT_TOURNAMENT_STATUS](state, payload) {
    state.formattedTournamentStatus = payload === 'InProgress' ? 'Active' : payload;
  },
};
