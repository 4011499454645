export default {
  SET_RETAIL_FEED: 'SET_RETAIL_FEED',
  SET_RETAIL_TOPWINS: 'SET_RETAIL_TOPWINS',
  UPDATE_RETAIL_FEED: 'UPDATE_RETAIL_FEED',
  POPULATE_STREAM: 'POPULATE_STREAM',
  SET_TOP_WINS_TAB: 'SET_TOP_WINS_TAB',
  SET_TOP_WINS_REFRESH: 'SET_TOP_WINS_REFRESH',
  CLEAR_STREAM: 'CLEAR_STREAM',
  SET_LOADING: 'SET_LOADING',
  SET_PUSHER_STATE: 'SET_PUSHER_STATE',
  SET_UNAVAILABLE: 'SET_UNAVAILABLE',
  SET_LOADING_MESSAGE: 'SET_LOADING_MESSAGE',
  REMOVE_RETAIL_TICKET: 'REMOVE_RETAIL_TICKET',
  SET_RETAIL_BASE_FONT_SIZE: 'SET_RETAIL_BASE_FONT_SIZE',
  SET_NUMBER_OF_TICKETS: 'SET_NUMBER_OF_TICKETS',
  MERGE_RETAIL_TOP_WINS: 'MERGE_RETAIL_TOP_WINS',
  SET_LOAD_FAILURE: 'SET_LOAD_FAILURE',
  SET_TOP_WINS_REFRESH_CYCLE: 'SET_TOP_WINS_REFRESH_CYCLE',
};
