<template>
  <div class="retailTemplate"
       :class="setTheme">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TemplateRetail',
  methods: {
    ...mapActions('retail', ['setRetailBaseFontSize']),
  },
  computed: {
    ...mapGetters(['config']),
    setTheme() {
      return `Rtheme-${this.config.theme || 'default'}`;
    },
  },
  created() {
    this.setRetailBaseFontSize();
  },
};
</script>

<style lang="scss">
.retailTemplate {
  height: 100vh;
}
</style>
