export default {
  boost: (locale) => {
    switch (locale) {
      case 'sr-Latn':
        return import(/* webpackChunkName: "i18n.common.sr-Latn" */'./boost.sr-Latn');
      case 'bs':
        return import(/* webpackChunkName: "i18n.common.bs" */'./boost.bs');
      case 'ro':
        return import(/* webpackChunkName: "i18n.common.ro" */'./boost.ro');
      default:
        return import(/* webpackChunkName: "i18n.common" */'./boost');
    }
  },
};
