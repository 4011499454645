import http from './http';

export default {
  async loadData(payload) {
    const response = await http.get(`/${payload.path}`, {});
    return response.data;
  },
  async putData(payload) {
    const response = await http.put(`/${payload.path}`, payload.data);
    return response.data;
  },
  async updateData(payload) {
    const response = await http.patch(`/${payload.path}`, payload.data);
    return response.data;
  },
};
