import {
  find,
  isEmpty,
  remove,
  some,
} from 'lodash';
import { swapTickets } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_RETAIL_FEED](state, payload) {
    state.retailFeedData = swapTickets(payload.data);
  },
  [types.SET_RETAIL_TOPWINS](state, payload) {
    if (!state.retailTopWinsData) {
      state.retailTopWinsData = payload.data;
    } else {
      state.stashedRetailTopWinsData = payload.data;
    }
  },
  [types.MERGE_RETAIL_TOP_WINS](state) {
    state.retailTopWinsData = state.stashedRetailTopWinsData;
    state.stashedRetailTopWinsData = '';
  },
  [types.UPDATE_RETAIL_FEED](state) {
    state.streamInterval = setInterval(() => {
      if (state.ticketStream.length) {
        let newTickets = [...state.retailFeedData];

        const newTicket = state.ticketStream.shift();

        if (!some(newTickets, ['id', newTicket?.id])) {
          newTickets.unshift(newTicket);
          newTickets = swapTickets(newTickets);
          if (newTickets.length > state.feedSize) newTickets.pop();

          state.retailFeedData = newTickets;
        }
      }

      if (isEmpty(state.ticketStream)) clearInterval(state.streamInterval);
    }, 500);
  },
  [types.POPULATE_STREAM](state, payload) {
    state.ticketStream.push(payload);
  },
  [types.SET_TOP_WINS_REFRESH_CYCLE](state, payload) {
    state.topWinsRefreshCycle = payload;
  },
  [types.SET_TOP_WINS_TAB](state, payload) {
    state.currentTopWinsTab = payload;
  },
  [types.SET_TOP_WINS_REFRESH](state, payload) {
    state.topWinsRefreshAvailable = payload;
  },
  [types.CLEAR_STREAM](state) {
    clearInterval(state.streamInterval);
    state.ticketStream.length = 0;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SET_PUSHER_STATE](state, payload) {
    state.pusherState = payload;
  },
  [types.SET_UNAVAILABLE](state, payload) {
    state.retailUnavailable = payload;
  },
  [types.SET_LOADING_MESSAGE](state, payload) {
    state.loadingMessage = payload;
  },
  [types.REMOVE_RETAIL_TICKET](state, payload) {
    const feedTicketToRemove = find(state.retailFeedData, ['id', payload.id]);
    const streamTicketToRemove = find(state.ticketStream, ['id', payload.id]);

    if (feedTicketToRemove) remove(state.retailFeedData, feedTicketToRemove);
    if (streamTicketToRemove) remove(state.ticketStream, feedTicketToRemove);
  },
  [types.SET_RETAIL_BASE_FONT_SIZE](state, payload) {
    state.baseFontSize = payload;
  },
  [types.SET_NUMBER_OF_TICKETS](state, payload) {
    state.feedSize = payload.feedSize;
    state.topWinsSize = payload.topWinsSize;
  },
  [types.SET_LOAD_FAILURE](state) {
    state.loadFailure = true;
  },
};
