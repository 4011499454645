export default {
  translations: undefined,
  config: null,
  navigation: {},
  settings: '',
  profileData: '',
  otherPlayerProfileData: '',
  otherPlayerId: '',
  newPlayer: false,
  feedData: '',
  topWinsData: '',
  newLevelAnimationState: undefined,
  boostState: '',
  activeLoader: false,
  errorDetails: '',
  visibleModules: undefined,
  progressBarPercentage: undefined,
  starsProgressBarPercentage: undefined,
  newLevelName: '',
  ticketStream: [],
  streamInterval: undefined,
  isOffline: false,
  activeTournaments: '',
  finishedTournaments: '',
  tournamentDetails: '',
  tournamentLeaderboard: '',
  playerLeaderboard: '',
  tournamentLeaderboardSize: 100,
  formattedTournamentStatus: '',
};
