export default {
  translation: (state) => (key) => state.translations[key],
  translations: (state) => state.translations,
  config: (state) => state.config,
  tenantId: (state) => state.config.tenantId,
  playerId: (state) => state.config.playerId,
  profileData: (state) => (state.otherPlayerId ? state.otherPlayerProfileData : state.profileData),
  feedData: (state) => state.feedData,
  otherPlayerId: (state) => state.otherPlayerId,
  topWinsData: (state) => state.topWinsData,
  dailyTopWins: (state) => state.topWinsData.daily,
  monthlyTopWins: (state) => state.topWinsData.monthly,
  weeklyTopWins: (state) => state.topWinsData.weekly,
  settings: (state) => state.settings,
  widget: (state) => state.config.widget,
  activeLoader: (state) => state.activeLoader,
  errorDetails: (state) => state.errorDetails,
  isAchievements: (state) => state.navigation.currentModule.toLowerCase() === 'achievements',
  newPlayer: (state) => state.newPlayer && !state.otherPlayerId,
  visibleModules: (state) => state.visibleModules,
  newLevelAnimationActive: (state) => state.newLevelAnimationState === 'active',
  newLevelAnimationReady: (state) => state.newLevelAnimationState === 'ready',
  progressBarPercentage: (state) => state.progressBarPercentage,
  starsProgressBarPercentage: (state) => state.starsProgressBarPercentage,
  newLevelName: (state) => state.newLevelName,
  isOffline: (state) => state.isOffline,
  minWinAmount: (state) => state.settings?.feed?.minWinAmount,
  channel: (state) => state.config.channel,
  navigation: (state) => state.navigation,
  boostState: (state) => state.boostState,
  activeTournaments: (state) => state.activeTournaments,
  finishedTournaments: (state) => state.finishedTournaments,
  tournamentDetails: (state) => state.tournamentDetails,
  tournamentLeaderboard: (state) => state.tournamentLeaderboard,
  playerLeaderboard: (state) => state.playerLeaderboard,
  tournamentLeaderboardSize: (state) => state.tournamentLeaderboardSize,
  formattedTournamentStatus: (state) => state.formattedTournamentStatus,
};
