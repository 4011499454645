export default function reloadHandler() {
  // current time
  const currentTime = new Date();

  // reload time
  const reloadTime = new Date().setHours(24, 0, 0);

  // time until reload
  let timeUntilReload = reloadTime - currentTime;

  // check if the reload time has passed
  if (timeUntilReload < 0) {
    const newReloadTime = new Date(reloadTime);

    // set reload time for next day
    newReloadTime.setDate(newReloadTime.getDate() + 1);
    timeUntilReload = newReloadTime - currentTime;
  }

  setTimeout(() => { window.location.reload(); }, timeUntilReload);
}
