<template>
  <div class="template"
       :class="setTheme">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TemplateDefault',
  methods: {
    ...mapActions(['setModulesVisibility']),
  },
  computed: {
    ...mapGetters(['config']),
    setTheme() {
      return `theme-${this.config.theme}`;
    },
  },
  mounted() {
    window.addEventListener('message', (event) => {
      if (event.data.action === 'openBoost') {
        this.setModulesVisibility(true);
      } else if (event.data.action === 'closeBoost') {
        this.setModulesVisibility(false);
      }
    });
  },
  created() {
    this.setModulesVisibility(this.config.showOnLoad);
  },
};
</script>
