import {
  reduce,
  isEqual,
  omit,
} from 'lodash';
import { roundDecimals } from './formatNumbers';

export const formatPrizes = (prizes) => reduce(prizes, (result, prize, index) => {
  const previousIteration = result;
  if (index === 0 || !isEqual(omit(prize, 'position'), omit(prizes[index - 1], 'position'))) {
    const formattedPrize = {
      fromPosition: prize.position,
      toPosition: prize.position,
      type: prize.type,
      details: prize.details,
    };
    previousIteration.push(formattedPrize);
  } else {
    previousIteration[previousIteration.length - 1].toPosition = prize.position;
  }
  return previousIteration;
}, []);

export const mapPrize = (prize, currency) => {
  if (prize.type === 'NSoftBalancePayout') {
    const formattedPrize = currency ? `${roundDecimals(prize.details.amount)} ${currency}` : roundDecimals(prize.details.amount);

    return formattedPrize || '-';
  }
  if (prize.type === 'NSoftCasinoFreeRounds') {
    return `${prize.details.prizeCountPerPlayer} FS`;
  }
  if (prize.type === 'Custom') {
    return prize.details.description || '-';
  }
  return '-';
};
