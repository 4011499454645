import store from '@/store';
import { some } from 'lodash';
import { formatLevelData, isDateBeforeNow } from '@/utility';
import Pusher from 'pusher-js';

export default {
  init() {
    let pusher;
    const { config } = store.getters;
    const channels = {
      isFeedChannel: config.route === 'modules' && store.getters.navigation.feed,
      isProfileChannel: config.route === 'modules' && config.playerId && store.getters.navigation.achievements,
      isTournamentsChannel: config.route === 'modules' && store.getters.navigation.tournaments,
      isRetailChannel: config.route === 'retail',
    };
    if (some(channels)) {
      pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, { cluster: 'eu', forceTLS: true });
    }

    if (channels.isFeedChannel) {
      const feedChannel = pusher.subscribe(`NSoft.Boost.TenantFeed.${config.tenantId}-${config.channel}-${config.widget}`);
      feedChannel.bind('Feed.ItemCreated', (data) => {
        if (store.getters.isOffline) {
          store.dispatch('refreshFeed');
        }
        if (store.getters.feedData && data) {
          store.dispatch('newTicket', data);
        }
        if (store.getters.boostState.unavailable) {
          pusher.unsubscribe(`NSoft.Boost.Tenant.${config.tenantId}_${config.widget}`);
        }
      });
      feedChannel.bind('Feed.ItemRemoved', (data) => {
        if (data) {
          store.dispatch('removeTicket', data);
        }
      });
    }

    if (channels.isProfileChannel) {
      const profileChannel = pusher.subscribe(`NSoft.Boost.Profile.${config.playerId}`);
      profileChannel.bind('Profile.Updated', (data) => {
        if (store.getters.profileData) {
          store.dispatch('levelUpdate', formatLevelData(data.level));
          store.dispatch('statsUpdate', data.statsLatest);
        }
        if (store.getters.boostState.unavailable) {
          pusher.unsubscribe(`NSoft.Boost.Profile.${config.playerId}`);
        }
      });
    }
    if (channels.isTournamentsChannel) {
      const tournamentsChannel = pusher.subscribe(`NSoft.Boost.TenantTournaments.${config.tenantId}`);
      tournamentsChannel.bind('Tournament.StatusUpdated', (data) => {
        store.dispatch('handleTournamentUpdate', { tournamentId: data.tournamentId, newStatus: data.status });
      });
      tournamentsChannel.bind('Tournament.Created', (data) => {
        if (isDateBeforeNow(data.visibilityStartTime)) {
          setTimeout(() => {
            store.dispatch('getTournaments');
          }, 2000);
        }
      });
      tournamentsChannel.bind('Tournament.Updated', (data) => {
        if (isDateBeforeNow(data.visibilityStartTime)) {
          setTimeout(() => {
            store.dispatch('handleTournamentUpdate', { tournamentId: data.tournamentId });
          }, 2000);
        }
      });
      tournamentsChannel.bind('Tournament.VisibilityStarted', () => {
        store.dispatch('getTournaments');
      });
      tournamentsChannel.bind('Tournament.VisibilityEnded', (data) => {
        store.dispatch('handleTournamentUpdate', { tournamentId: data.tournamentId, visibilityEnded: true });
      });
    }

    if (channels.isRetailChannel) {
      const retailFeedChannel = pusher.subscribe(`NSoft.Boost.TenantFeed.${config.tenantId}-${config.channel || 'Location'}-${config.widget}`);
      retailFeedChannel.bind('Feed.ItemCreated', (data) => {
        if (store.getters['retail/retailFeedData']) {
          store.dispatch('retail/newTicket', data);
        }
      });
      retailFeedChannel.bind('Feed.ItemRemoved', (data) => {
        if (data) {
          store.dispatch('retail/removeRetailTicket', data);
        }
      });

      pusher.connection.bind('connecting', () => {
        if (store.getters['retail/pusherState'].previous === 'connected') {
          store.dispatch('retail/setUnavailable');
        }
        if (store.getters['retail/isUnavailable']) {
          store.dispatch('retail/setLoadingMessage', 'retailConnecting');
        }
      });

      pusher.connection.bind('unavailable', () => {
        store.dispatch('retail/setLoadingMessage', 'retailUnavailable');
      });

      pusher.connection.bind('connected', () => {
        if (store.getters['retail/isUnavailable']) {
          store.dispatch('retail/removeUnavailable');
        }
      });

      pusher.connection.bind('state_change', (state) => {
        store.dispatch('retail/setPusherState', state);
      });
    }
  },
};
