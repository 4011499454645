export default function getConfiguration() {
  const params = new URLSearchParams(window.location ? window.location.search : undefined);

  const config = {
    badgeSize: params.get('badgeSize'),
    badgeType: params.get('badgeType'),
    theme: params.get('theme'),
    playerId: params.get('profileId') === 'undefined' ? undefined : params.get('profileId'),
    tenantId: params.get('tenantId'),
    showOnLoad: JSON.parse(params.get('showOnLoad')),
    modulesAnimation: params.get('modulesAnimation'),
    visibleBadge: JSON.parse(params.get('visibleBadge')),
    widget: params.get('widget'),
    authorizationToken: params.get('token'),
    quality: params.get('quality'),
    channel: params.get('channel'),
    language: params.get('language'),
  };

  return config;
}
