export default {
  retailFeedData: '',
  ticketStream: [],
  retailTopWinsData: '',
  stashedRetailTopWinsData: '',
  streamInterval: undefined,
  feedSize: undefined,
  topWinsSize: undefined,
  topWinsTabs: [
    'daily',
    'weekly',
    'monthly',
  ],
  currentTopWinsTab: '',
  topWinsRefreshAvailable: false,
  loading: false,
  loadingMessage: '',
  pusherState: {},
  retailUnavailable: false,
  baseFontSize: undefined,
  loadFailure: false,
  topWinsRefreshCycle: false,
};
