import {
  keys,
  mapKeys,
  pickBy,
  capitalize,
} from 'lodash';

export default function mapState(response, config) {
  const {
    feed = {},
    modules = {},
    profile = {},
    tenant = {},
  } = response.data || {};

  // map profile and get only visible modules
  const mappedModules = mapKeys(pickBy(modules, 'show'), (_, key) => (key === 'profile' ? 'achievements' : key));
  // remove achievements module for demo mode
  if (!config.playerId) delete mappedModules.achievements;
  // get array of active modules
  const activeModules = keys(mappedModules);
  // set current module, if no response - error
  const currentModule = response.data ? capitalize(activeModules[0]) : 'Error';

  // create navigation object
  const navigation = {
    modules: activeModules,
    action: '',
    title: '',
    currentModule,
    animation: '',
    ...mappedModules,
  };
  /*
    check will boost stream bet tickets or only winning tickets. For only winning tickets
    there should be only one ticket type (Win - virtuals or BetAndWin - casino),
  */
  const betTickets = !(feed?.types?.length === 1 && (feed?.types[0] === 'Win' || feed?.types[0] === 'BetAndWin'));

  // set initial modules visibility (defined in embedScript - showOnLoad)
  const modulesVisibility = config.showOnLoad;

  // is viewing other players profile available for demo and registered players
  const canViewOtherProfiles = (!config.playerId && feed?.privacy?.profileAccess?.guest)
    || (config.playerId && feed?.privacy?.profileAccess?.registered);

  // set initial incognito mode status
  const isMasked = profile?.feed?.isMasked;

  // get visible feed columns (columns shown inside feed and top wins)
  const feedColumns = keys(pickBy(feed?.fields));

  // set unavailable if request failed
  const unavailable = !response.data;

  // set retail channel in web integration
  const isRetailIntegration = config.channel === 'Location';

  // set number of tickets inside feed...for retail integration there should be fixed number of
  // tickets so scrollbar is hidden, for non-retail integration 35 is fixed size
  // feedSize = window - (navigation + headers) / base ticket height
  const feedSize = isRetailIntegration ? Math.floor((window.innerHeight - 70) / 36) : 35;

  // is tenant using custom display names for players
  const isNicknameAllowed = tenant?.config?.useCustomProfileDisplayName && !profile?.isCustomDisplayNameSet;

  // create boostState object
  const boostState = {
    betTickets,
    modulesVisibility,
    canViewOtherProfiles,
    isMasked,
    feedColumns,
    unavailable,
    isRetailIntegration,
    feedSize,
    isNicknameAllowed,
  };
  return { navigation, boostState };
}
