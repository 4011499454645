import {
  cloneDeep,
  find,
  pullAll,
  set,
  unset,
} from 'lodash';

export default function swapTickets(payload) {
  const tickets = cloneDeep(payload);
  const ticketsWithReference = [];
  tickets.forEach((ticket) => {
    if (ticket.refBetId && ticket.subType !== 'Jackpot') {
      const betTicket = find(tickets, { id: ticket.refBetId });
      if (betTicket) {
        ticketsWithReference.push(ticket);
        set(betTicket, 'winAmount', ticket.winAmount);
        set(betTicket, 'subType', ticket?.subType);
      } else {
        set(ticket, 'betAmount', ticket.refBetAmount);
        unset(tickets, 'refBetAmount');
      }
    }
  });
  pullAll(tickets, ticketsWithReference);
  return tickets;
}
