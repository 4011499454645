import { h } from 'vue';

export default {
  install: function install(Vue) {
    Vue.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      render() {
        const key = this.$slots.default()[0].children;
        return [h('span', this.$store.getters.translation(key))];
      },
    });
  },
};
