export default {
  SET_PROFILE: 'SET_PROFILE',
  SET_FEED: 'SET_FEED',
  SET_OTHER_PLAYER_ID: 'SET_OTHER_PLAYER_ID',
  DELETE_OTHER_PLAYER_PROFILE_DATA: 'DELETE_OTHER_PLAYER_PROFILE_DATA',
  SET_TOPWINS: 'SET_TOPWINS',
  TOGGLE_NEW_LEVEL_POPUP: 'TOGGLE_NEW_LEVEL_POPUP',
  SET_BOOST_STATE: 'SET_BOOST_STATE',
  UPDATE_BOOST_STATE: 'UPDATE_BOOST_STATE',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  SET_ERROR_DETAILS: 'SET_ERROR_DETAILS',
  CLOSE_ERROR: 'CLOSE_ERROR',
  SET_OTHER_PLAYER_PROFILE: 'SET_OTHER_PLAYER_PROFILE',
  SET_MODULES_VISIBILITY: 'SET_MODULES_VISIBILITY',
  SET_NEW_LEVEL_ANIMATION_STATE: 'SET_NEW_LEVEL_ANIMATION_STATE',
  UPDATE_PROGRESS_BAR: 'UPDATE_PROGRESS_BAR',
  UPDATE_STARS_PROGRESS_BAR: 'UPDATE_STAR_PROGRESS_BAR',
  SET_NEW_LEVEL_NAME: 'SET_NEW_LEVEL_NAME',
  UPDATE_FEED: 'UPDATE_FEED',
  POPULATE_STREAM: 'POPULATE_STREAM',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  SET_NEW_PLAYER: 'SET_NEW_PLAYER',
  REMOVE_TICKET: 'REMOVE_TICKET',
  SET_OFFLINE: 'SET_OFFLINE',
  CLEAR_STREAM: 'CLEAR_STREAM',
  SET_NAVIGATION: 'SET_NAVIGATION',
  UPDATE_NAVIGATION: 'UPDATE_NAVIGATION',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_ACTIVE_TOURNAMENTS: 'SET_ACTIVE_TOURNAMENTS',
  SET_FINISHED_TOURNAMENTS: 'SET_FINISHED_TOURNAMENTS',
  SET_TOURNAMENT_DETAILS: 'SET_TOURNAMENT_DETAILS',
  SET_TOURNAMENT_LEADERBOARD: 'SET_TOURNAMENT_LEADERBOARD',
  SET_PLAYER_LEADERBOARD: 'SET_PLAYER_LEADERBOARD',
  UPDATE_TOURNAMENT_LEADERBOARD: 'UPDATE_TOURNAMENT_LEADERBOARD',
  CLEAR_TOURNAMENT_LEADERBOARD: 'CLEAR_TOURNAMENT_LEADERBOARD',
  EXPAND_LEADERBOARD: 'EXPAND_LEADERBOARD',
  UPDATE_TENANT_ID: 'UPDATE_TENANT_ID',
  FORMAT_TOURNAMENT_STATUS: 'FORMAT_TOURNAMENT_STATUS',
};
