import {
  format,
  isBefore,
  parseISO,
} from 'date-fns';

// format dates
export const isoToStringDate = (date) => format(parseISO(date), 'MMM dd HH:mm a');
export const isoToShortStringDate = (date) => format(parseISO(date), 'MMM dd, HH:mm');
export const isoToNumberDate = (date) => format(parseISO(date), 'dd. MM. yyyy. - HH:mm');
export const isoToNumberShortDate = (date) => format(parseISO(date), 'dd.MM.yyyy.');

// comparisons
export const isDateBeforeNow = (date) => isBefore(parseISO(date), new Date());
