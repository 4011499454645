import {
  formatLevelData,
  getAnimationType,
  mapState,
  sendMessage,
} from '@/utility';
import { upperFirst, isEmpty } from 'lodash';
import types from './mutationTypes';
import { dataApi } from '../api';

export default {
  async loadData({ dispatch }, payload) {
    const response = await dispatch('apiHandler', { action: 'loadData', payload });
    return response;
  },
  async putData({ dispatch }, payload) {
    const response = await dispatch('apiHandler', { action: 'putData', payload });
    return response;
  },
  async updateData({ dispatch }, payload) {
    const response = await dispatch('apiHandler', { action: 'updateData', payload });
    return response;
  },
  async apiHandler({ commit, dispatch }, data) {
    try {
      const response = await dataApi[data.action](data.payload);
      if (data.payload.name) {
        commit(types[`SET_${data.payload.name.toUpperCase()}`], response);
      }
      return response;
    } catch (error) {
      if (data.payload.required) {
        dispatch('navigate', { to: 'error' });
        commit(types.TOGGLE_LOADER, false);
      }
      return { error };
    }
  },
  async getProfile({ commit, getters, dispatch }) {
    const response = await dispatch('loadData', {
      path: `profiles/${getters.playerId}?widget=${getters.widget}`,
      required: !getters.profileData,
    });
    if (response.data) {
      if (isEmpty(response.data)) {
        commit(types.SET_NEW_PLAYER, true);
      }
      const formattedLevelData = formatLevelData(response);
      commit(types.SET_PROFILE, formattedLevelData);
      commit(types.UPDATE_STARS_PROGRESS_BAR, response?.data?.level?.currentLevelPct);
      dispatch('updateProgressBar', response?.data?.level?.currentLevelPct);
    }
  },
  async getOtherPlayerProfile({ dispatch, commit, getters }) {
    const response = await dispatch('loadData', { path: `profiles/${getters.otherPlayerId}?widget=${getters.widget}` });
    if (response.data) {
      commit(types.SET_OTHER_PLAYER_PROFILE, formatLevelData(response));
      setTimeout(() => {
        dispatch('toggleLoader', false);
      }, 200);
    }
    if (response.error) {
      setTimeout(() => {
        dispatch('closeOtherPlayerProfile');
        dispatch('toggleLoader', false);
        commit(types.SET_ERROR_DETAILS, { selfClosing: true });
      }, 500);
    }
  },
  async getFeed({ dispatch, getters }) {
    await dispatch('loadData', {
      path: `feed/${getters.tenantId}?size=${getters.boostState.feedSize}&widget=${getters.widget}&feedType=${getters.channel}`,
      name: 'feed',
      required: !getters.feedData,
    });
  },
  async maskDisplayName({ commit, dispatch, getters }) {
    const response = await dispatch('putData', {
      path: `profiles/${getters.playerId}/settings/is-masked`,
      data: { isMasked: !getters.boostState.isMasked },
    });
    if (response.success) {
      commit(types.UPDATE_BOOST_STATE, { prop: 'isMasked', value: !getters.boostState.isMasked });
      dispatch('getProfile');
    }
    if (response.error) commit(types.SET_ERROR_DETAILS);
  },
  async setCustomDisplayName({ dispatch, getters, state }, payload) {
    const response = await dispatch('putData', {
      path: `profiles/${getters.playerId}/settings/display-name`,
      data: { customDisplayName: payload },
    });

    if (response.error) {
      const errorKey = response.error.response.data.code === 'DISPLAY_NAME_NOT_UNIQUE' ? 'NameTaken' : 'GlobalError';
      return {
        error: true,
        text: state.translations[`nicknameValidationMessage${errorKey}`],
      };
    }

    return {
      success: true,
      text: state.translations.nicknameValidationMessageSuccess,
    };
  },
  async getTopWins({ dispatch, getters }) {
    await dispatch('loadData', {
      path: `feed/${getters.tenantId}/top-wins?widget=${getters.widget}&size=${getters.boostState.feedSize}&feedType=${getters.channel}`,
      name: 'topWins',
      required: !getters.topWinsData,
    });
  },
  async getSettings({ dispatch, getters, commit }) {
    const response = await dispatch('loadData', { path: `feed/${getters.tenantId}/settings?widget=${getters.widget}&profileType=${getters.channel}&token=${getters.config.authorizationToken || ''}` });

    const { navigation, boostState } = mapState(response, getters.config);
    if (response.data?.tenant?.id) {
      commit(types.UPDATE_TENANT_ID, response.data.tenant.id);
    }
    commit(types.SET_SETTINGS, response);
    commit(types.SET_NAVIGATION, navigation);
    commit(types.SET_BOOST_STATE, boostState);
  },
  async getTournaments({ dispatch, getters }) {
    await dispatch('loadData', {
      path: 'tournaments/active',
      name: 'active_tournaments',
      required: !getters.activeTournaments,
    });
    await dispatch('loadData', {
      path: 'tournaments/finished',
      name: 'finished_tournaments',
      required: !getters.finishedTournaments,
    });
  },
  async getTournamentDetails({ dispatch, commit, getters }, payload) {
    dispatch('toggleLoader', true);
    try {
      const tournamentDetails = await dispatch('loadData', { path: `tournaments/${payload.tournamentId}/details` });
      commit(types.SET_TOURNAMENT_DETAILS, tournamentDetails);

      await dispatch('getTournamentLeaderboard', { page: 1 });
      dispatch('navigate', {
        to: 'tournamentDetails',
        action: 'closeTournamentDetails',
        title: getters.tournamentDetails.displayName,
        animation: 'fade-left',
      });

      setTimeout(() => {
        dispatch('toggleLoader', false);
      }, 200);
    } catch (error) {
      setTimeout(() => {
        dispatch('toggleLoader', false);
        commit(types.SET_ERROR_DETAILS, { selfClosing: true });
      }, 500);
    }
  },
  async getTournamentLeaderboard({ dispatch, getters, commit }, payload) {
    const tournamentLeaderboard = await dispatch('loadData', { path: `tournaments/${getters.tournamentDetails.id}/leaderboard?page=${payload.page}&size=${getters.tournamentLeaderboardSize}` });
    commit(types.SET_TOURNAMENT_LEADERBOARD, tournamentLeaderboard);

    if (getters.playerId) {
      const playerLeaderboard = await dispatch('loadData', { path: `tournaments/${getters.tournamentDetails.id}/leaderboard/positions/${getters.playerId}` });
      commit(types.SET_PLAYER_LEADERBOARD, playerLeaderboard);
    }
  },
  async expandLeaderboard({ dispatch, commit, getters }, payload) {
    const leaderboard = await dispatch('loadData', { path: `tournaments/${getters.tournamentDetails.id}/leaderboard?size=${payload.page * getters.tournamentLeaderboardSize}` });
    commit(types.EXPAND_LEADERBOARD, { data: leaderboard.data, page: payload.page });
  },
  async refreshTournamentLeaderboards({ dispatch, commit, getters }) {
    const s = getters.tournamentLeaderboardSize;
    const numberOfPlayers = Math.ceil(getters.tournamentLeaderboard.data.length / s) * s || s;
    const tournamentLeaderboard = await dispatch('loadData', { path: `tournaments/${getters.tournamentDetails.id}/leaderboard?size=${numberOfPlayers}` });
    if (tournamentLeaderboard.data) {
      commit(types.UPDATE_TOURNAMENT_LEADERBOARD, tournamentLeaderboard);
    }

    if (getters.playerId) {
      const playerLeaderboard = await dispatch('loadData', { path: `tournaments/${getters.tournamentDetails.id}/leaderboard/positions/${getters.playerId}` });
      if (playerLeaderboard.data) {
        commit(types.SET_PLAYER_LEADERBOARD, playerLeaderboard);
      }
    }
  },
  async handleTournamentUpdate({ dispatch, commit, getters }, payload) {
    await dispatch('getTournaments');

    if (getters.navigation?.currentModule === 'TournamentDetails' && payload.tournamentId === getters.tournamentDetails?.id) {
      dispatch('toggleLoader', true);
      if (payload.newStatus === 'Canceled' || payload.newStatus === 'Stopped' || payload.visibilityEnded) {
        dispatch('closeTournamentDetails');
      } else {
        const tournamentDetails = await dispatch('loadData', { path: `tournaments/${payload.tournamentId}/details` });
        if (tournamentDetails.data) {
          commit(types.SET_TOURNAMENT_DETAILS, tournamentDetails);
        }
        await dispatch('refreshTournamentLeaderboards');
      }

      setTimeout(() => { dispatch('toggleLoader', false); }, 500);
    }
  },
  navigate({ commit, state }, payload) {
    const navigation = {
      animation: getAnimationType(state.navigation.modules, payload.to, state.navigation.currentModule),
    };

    if ('to' in payload) {
      navigation.currentModule = upperFirst(payload.to);
    }
    if ('action' in payload) {
      if (payload.action === '') {
        delete navigation.animation;
      }
      navigation.action = payload.action;
    }
    if ('title' in payload) {
      navigation.title = payload.title;
    }
    if ('animation' in payload) {
      navigation.animation = payload.animation;
    }
    commit(types.UPDATE_NAVIGATION, navigation);
  },
  updateBoostState({ commit }, payload) {
    commit(types.UPDATE_BOOST_STATE, payload);
  },
  lazyLoadRequests({ dispatch, getters }) {
    if (getters.navigation.achievements) {
      dispatch('getProfile');
    }
    if (getters.navigation.feed) {
      dispatch('getFeed');
      if (!getters.boostState.isRetailIntegration) {
        dispatch('getTopWins');

        // Refresh top wins every 5 minutes
        setInterval(() => {
          dispatch('getTopWins');
        }, 300_000);
      }
    }
    if (getters.navigation.tournaments) {
      dispatch('getTournaments');
    }
  },
  setPofile({ commit }, payload) {
    commit(types.SET_PROFILE, payload);
  },
  updateProgressBar({ commit }, payload) {
    commit(types.UPDATE_PROGRESS_BAR, payload);
    sendMessage({
      action: 'updateProgressBar',
      recipient: 'badge',
      value: payload,
    });
  },
  setOtherPlayerId({ commit }, payload) {
    commit(types.SET_OTHER_PLAYER_ID, payload);
  },
  deleteOtherPlayerProfileData({ commit }) {
    commit(types.DELETE_OTHER_PLAYER_PROFILE_DATA);
  },
  viewOtherPlayerProfile({ dispatch, commit }, payload) {
    if (payload) {
      dispatch('toggleLoader', true);
      dispatch('setOtherPlayerId', payload);
      dispatch('navigate', { to: 'achievements', action: 'closeOtherPlayerProfile' });
      dispatch('getOtherPlayerProfile');
    } else {
      commit(types.SET_ERROR_DETAILS, { selfClosing: true });
    }
  },
  closeTournamentDetails({ dispatch, commit }) {
    setTimeout(() => {
      dispatch('navigate', { action: '', title: '' });
    }, 200);
    dispatch('navigate', { to: 'tournaments' });
    commit(types.CLEAR_TOURNAMENT_LEADERBOARD);
  },
  closeOtherPlayerProfile({ dispatch }) {
    setTimeout(() => {
      dispatch('navigate', { action: '' });
    }, 200);
    dispatch('setOtherPlayerId', '');
    dispatch('deleteOtherPlayerProfileData');
    dispatch('navigate', { to: 'feed' });
  },
  toggleNewLevelPopup({ commit }, payload) {
    commit(types.TOGGLE_NEW_LEVEL_POPUP, payload);
  },
  toggleLoader({ commit }, payload) {
    commit(types.TOGGLE_LOADER, payload);
  },
  setErrorDetails({ commit }, payload) {
    commit(types.SET_ERROR_DETAILS, payload);
  },
  closeError({ commit }) {
    commit(types.CLOSE_ERROR);
  },
  setModulesVisibility({ commit }, payload) {
    commit(types.SET_MODULES_VISIBILITY, payload);
  },
  updateStarsProgressBar({ commit }, payload) {
    commit(types.UPDATE_STARS_PROGRESS_BAR, payload);
  },
  newTicket({ state, commit }, payload) {
    commit(types.POPULATE_STREAM, payload);

    if (state.feedData && state.ticketStream.length === 1) {
      commit(types.UPDATE_FEED);
    }
  },
  levelUpdate({
    state,
    commit,
    getters,
    dispatch,
  }, payload) {
    if (payload && state.newPlayer) {
      commit(types.SET_NEW_PLAYER, false);
    }
    commit(types.SET_NEW_LEVEL_NAME, payload.levelName?.currentLevelName);
    if (state.profileData?.level?.levelNumber < payload.levelNumber) {
      dispatch('updateProgressBar', [100, payload.currentLevelPct]);
      commit(types.UPDATE_STARS_PROGRESS_BAR, 100);
      setTimeout(() => {
        dispatch('updateProgressBar', payload.currentLevelPct);
        commit(types.UPDATE_STARS_PROGRESS_BAR, payload.currentLevelPct);
      }, 2200);
      if (getters.isAchievements && state.visibleModules && !state.otherPlayerId) {
        setTimeout(() => {
          commit(types.SET_NEW_LEVEL_ANIMATION_STATE, 'active');
        }, 2000);
        setTimeout(() => {
          commit(types.UPDATE_PROFILE, { data: payload, type: 'level' });
        }, 2200);
      } else {
        commit(types.SET_NEW_LEVEL_ANIMATION_STATE, 'ready');
        commit(types.UPDATE_PROFILE, { data: payload, type: 'level' });
      }
    } else {
      commit(types.UPDATE_STARS_PROGRESS_BAR, payload.currentLevelPct);
      dispatch('updateProgressBar', payload.currentLevelPct);
      commit(types.UPDATE_PROFILE, { data: payload, type: 'level' });
    }
  },
  statsUpdate({ commit }, payload) {
    commit(types.UPDATE_PROFILE, { data: payload, type: 'statsLatest' });
  },
  removeTicket({ commit }, payload) {
    commit(types.REMOVE_TICKET, payload);
  },
  setOffline({ commit }, payload) {
    commit(types.SET_OFFLINE, payload);
  },
  async refreshFeed({ commit, dispatch }) {
    commit(types.SET_OFFLINE, false);
    commit(types.TOGGLE_LOADER, true);
    commit(types.CLEAR_STREAM);
    commit(types.SET_FEED, '');
    await dispatch('getFeed');
    commit(types.TOGGLE_LOADER, false);
  },
  formatTournamentStatus({ commit, getters }) {
    commit(types.FORMAT_TOURNAMENT_STATUS, getters.tournamentDetails.status);
  },
};
