import {
  get,
  has,
  isEmpty,
  set,
} from 'lodash';

export default function formatLevelData(response) {
  if (isEmpty(response.data) && response.data) {
    return response;
  }

  const path = has(response, 'data.level.levelName') ? 'data.level.levelName' : 'levelName';
  const formattedData = response;
  const currentLevelName = get(response, path).toLowerCase().split('_')[0];
  let currentLevelSublevel = get(response, path).toLowerCase().split('_')[1];
  const levelNumber = get(response, 'data.level.levelNumber', get(response, 'levelNumber'));

  if (!currentLevelSublevel || (levelNumber && levelNumber.toString().length === 1)) {
    set(formattedData, path, { currentLevelName, currentLevelSublevel });
    return formattedData;
  }

  currentLevelSublevel = Number(currentLevelSublevel) - 1;
  set(formattedData, path, { currentLevelName, currentLevelSublevel });
  return formattedData;
}
