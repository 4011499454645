import { gsap, SteppedEase } from 'gsap';

// Utility animations - no export

const bounceScale = (element) => {
  const animation = gsap
    .timeline()
    .to(element, { scale: 1.06, duration: 0.4 })
    .to(element, { scale: 0.97, duration: 0.15 })
    .to(element, { scale: 1, duration: 0.12 });

  return animation;
};

const spritePlay = (element, start, end, duration, steps) => {
  const animation = gsap.fromTo(
    element,
    {
      backgroundPosition: `100% ${start}%`,
    },
    {
      backgroundPosition: `100% ${end}%`,
      duration,
      ease: SteppedEase.config(steps),
    },
  );

  return animation;
};

const ticketLabelAnimation = (parent) => {
  const element = parent.querySelector('.statusLabel');
  const animation = gsap.from(element, { xPercent: 50, opacity: 0, duration: 0.3 });

  return animation;
};

// New level sprite - can be modified in the future !!
export const burst = () => {
  gsap
    .timeline()
    .fromTo('.levelInfo',
      {
        opacity: 0,
        scale: 0.8,
        y: -30,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        delay: 0.5,
        y: 0,
      })
    .fromTo('.levelBadge',
      { scale: 1.3, opacity: 0 },
      {
        scale: 1.5,
        opacity: 1,
        duration: 0.2,
      })
    .to('.levelBadge', {
      scale: 1,
      duration: 0.15,
      ease: 'ease-out',
    })
    .fromTo('.burstAnimation',
      { backgroundPosition: '0 15360px' },
      { backgroundPosition: '0 0', duration: 2, ease: SteppedEase.config(48) }, '-=0.1')
    .fromTo('.closeBtn', { opacity: 0 }, { opacity: 1, duration: 0.2, delay: 0.2 }, '-=0.5')
    .to('.pulsCircle',
      {
        height: 400,
        width: 400,
        opacity: 0,
        duration: 4,
        stagger: {
          each: 0.8,
          repeat: -1,
        },
        ease: 'ease-out',
      }, '-=0.2');
};

export const animateStar = (index) => {
  gsap
    .timeline()
    .fromTo(`.star-${index}`,
      {
        scale: 0.8,
        opacity: 0,
      },
      {
        scale: 1.4,
        opacity: 1,
        duration: 0.3,
      })
    .to(`.star-${index}`, { scale: 1, duration: 0.2 });
};

export const animateStars = () => {
  gsap
    .timeline()
    .to('.star', {
      scale: 1.6,
      stagger: 0.1,
      delay: 1,
      duration: 0.25,
    })
    .to('.star',
      {
        scale: 1,
        stagger: 0.1,
        duration: 0.2,
      }, '-=0.35');
};

export const retailBigWinTicketAnimation = (element) => {
  gsap
    .timeline()
    .add(spritePlay(element, 9600, 100, 4, 95))
    .add(ticketLabelAnimation(element), '<0.3');
};

export const retailJackpotTicketAnimation = (element) => {
  gsap
    .timeline()
    .add(spritePlay(element, 9600, 100, 4, 95))
    .add(ticketLabelAnimation(element), '<0.3')
    .add(bounceScale(element), '<1');
};

export const retailNewTopWinTicketAnimation = (element) => {
  gsap
    .timeline()
    .fromTo(element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
        delay: 0.3,
      })
    .add(bounceScale(element), '<')
    .add(spritePlay(element, 7200, 0, 3, 72), 0.6);
};

export const retailFeedTicketAnimation = async (baseFontSize) => {
  await gsap
    .timeline()
    .fromTo('.retailFeedTicket',
      { y: -5 * baseFontSize },
      { y: 1.5 * baseFontSize, duration: 0.333 })
    .to('.retailFeedTicket', { y: 0, duration: 0.167 });
};

export const retailTopWinsSwitchEnter = async (baseFontSize) => {
  const betshopData = document.querySelectorAll('.topWinsFirstPlace *');
  const betshopStartCoo = window.screen.height / 2 - betshopData[0]?.getBoundingClientRect()?.top;
  const placeholder = document.querySelector('.retailTopWinsPlaceholder');

  const columns = [
    '.topWinTicket .header.profileDisplayName',
    '.topWinTicket .header.productName',
    '.topWinTicket .header.winAmount',
  ];

  if (!placeholder) {
    await gsap
      .timeline()
      .set(betshopData[0], { y: betshopStartCoo - 3 * baseFontSize, opacity: 0 })
      .set(betshopData[1], { y: betshopStartCoo + 3 * baseFontSize, opacity: 0 })
      .set(betshopData[2], { y: betshopStartCoo + 3 * baseFontSize, opacity: 0 })
      .set(columns, { opacity: 0, x: baseFontSize / 2 })
      .set('.topWinsDescription p', { opacity: 0, y: -baseFontSize / 2 })
      .to('.topWinsDescription p', { opacity: 1, y: 0, duration: 0.25 })
      .to(betshopData, {
        opacity: 1,
        y: betshopStartCoo,
        duration: 0.7,
        stagger: (index) => index * 0.3,
      })
      .from('.topWinsFirstPlace', { scale: 1.3, duration: 0.4, delay: 0.2 })
      .to(betshopData, { y: 0, duration: 0.8, stagger: 0.1 }, '-=0.25')
      .to('.topWinsDivider', { width: '95%', duration: 0.5 })
      .to(columns, {
        opacity: 1,
        x: 0,
        stagger: {
          amount: 0.7,
        },
      }, '<');
  } else {
    await gsap
      .timeline()
      .set('.topWinsDescription p', { opacity: 0, y: -baseFontSize / 2 })
      .to('.topWinsDescription p', { opacity: 1, y: 0, duration: 0.25 })
      .fromTo(placeholder, { opacity: 0 }, { opacity: 1, duration: 1 });
  }
};

export const retailTopWinsSwitchLeave = (baseFontSize) => {
  const betshopData = document.querySelectorAll('.topWinsFirstPlace *');
  const columns = [
    '.topWinTicket .header.profileDisplayName',
    '.topWinTicket .header.productName',
    '.topWinTicket .header.winAmount',
  ];
  const placeholder = document.querySelector('.retailTopWinsPlaceholder');

  if (!placeholder) {
    gsap
      .timeline()
      .to('.topWinsDivider', { width: '0%', duration: 0.5 })
      .to(columns, {
        opacity: 0,
        x: 0,
        duration: 0.5,
        stagger: {
          amount: 0.5,
        },
        reversed: true,
      }, '<')
      .to(betshopData, {
        opacity: 0,
        y: 2 * baseFontSize,
        stagger: 0.1,
        reversed: true,
      })
      .to('.topWinsDescription p', { opacity: 0, y: baseFontSize, duration: 0.25 }, '-=0.25');
  } else {
    gsap
      .timeline()
      .to(placeholder, { opacity: 0, duration: 1.7 })
      .to('.topWinsDescription p', { opacity: 0, y: baseFontSize, duration: 0.25 }, '-=0.25');
  }
};

export const topBetshopSwap = () => {
  const items = document.querySelectorAll('.topWinsFirstPlace *');
  gsap
    .timeline()
    .to(items, { opacity: 0, duration: 1 })
    .set([items[1], items[2]], { scale: 0 })
    .to(items[1], { opacity: 1, duration: 0.5, scale: 1 })
    .to(items[0], {
      opacity: 1,
      duration: 0.5,
      delay: 0.2,
    })
    .to(items[2], { opacity: 1, duration: 0.25 })
    .to(items[2], { duration: 0.5, scale: 1, ease: 'back' }, '<');
};

export const tournamentPositionBlink = (element) => {
  gsap
    .timeline()
    .to(element, {
      opacity: element.style.opacity === '1' ? 0 : 1,
      duration: 0.5,
      repeat: 5,
      yoyo: true,
      ease: 'power1.inOut',
    })
    .to(element, {
      opacity: 1,
      duration: 0.5,
    });
};
