import axios from 'axios';
import state from '@/store/state';
import { v4 as uuidv4 } from 'uuid';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 10000,
});

http.interceptors.request.use((config) => {
  const paramReasign = config;

  paramReasign.headers = {
    ...paramReasign.headers,
    'x-nsft-tenant-id': state.config.tenantId,
    'x-request-id': uuidv4() || 'sup',
    'x-nsft-profile-id': state.config.playerId,
    authorization: state.config.authorizationToken,
  };

  return paramReasign;
}, (error) => Promise.reject(error));

export default http;
