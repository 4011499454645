<template>
  <img :src="fullSrc"
       @error="onError"
       v-if="isImageVisible"
       draggable="false"
       @contextmenu.prevent>
</template>

<script>
import { startsWith } from 'lodash';

export default {
  name: 'ImageHandler',
  props: [
    'src',
    'fallback',
    'willUpdate',
  ],
  data() {
    return {
      isImageVisible: true,
      fallbackSet: false,
    };
  },
  methods: {
    onError(e) {
      if (this.fallback && !this.fallbackSet) {
        // eslint-disable-next-line no-param-reassign
        e.target.src = `/assets/fallbacks/${this.fallback}`;
        this.fallbackSet = !this.willUpdate;
      } else {
        this.isImageVisible = false;
      }
    },
  },
  computed: {
    fullSrc() {
      return this.isExternalSrc ? this.src : `/assets/${this.src}`;
    },
    isExternalSrc() {
      return startsWith(this.src, 'http');
    },
  },
};
</script>
