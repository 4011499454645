import { topBetshopSwap } from '@/utility';
import { isEqual } from 'lodash';
import { dataApi } from '../../../api';
import types from './mutationTypes';

export default {
  async getRetailFeed({ commit, rootGetters, getters }) {
    try {
      const response = await dataApi.loadData({
        path: `feed/${rootGetters.tenantId}?size=${getters.feedSize}&widget=${rootGetters.widget}&feedType=${rootGetters.channel || 'Location'}`,
        name: 'feed',
        tenantId: rootGetters.tenantId,
        playerId: rootGetters.playerId,
      });
      commit(types.SET_RETAIL_FEED, response);
    } catch (error) {
      if (!getters.retailFeedData) {
        commit(types.SET_LOAD_FAILURE);
        commit(types.SET_LOADING_MESSAGE, 'loadFailureMessage');
      }
    }
  },
  async getRetailTopWins({ commit, rootGetters, getters }) {
    try {
      const response = await dataApi.loadData({
        path: `feed/${rootGetters.tenantId}/top-wins?widget=${rootGetters.widget}&size=${getters.topWinsSize}&feedType=${rootGetters.channel || 'Location'}`,
        name: 'topWins',
        tenantId: rootGetters.tenantId,
        playerId: rootGetters.playerId,
      });
      commit(types.SET_TOP_WINS_REFRESH_CYCLE, false);

      commit(types.SET_RETAIL_TOPWINS, response);
    } catch (error) {
      if (!getters.retailTopWinsData) {
        commit(types.SET_LOAD_FAILURE);
        commit(types.SET_LOADING_MESSAGE, 'loadFailureMessage');
      }
    }
  },
  async mergeRetailTopWins({ commit, getters }) {
    if (getters.retailTopWinsData && getters.stashedRetailTopWinsData) {
      const isNewTopBetshop = getters.retailTopWinsData.daily.length > 1
        && !isEqual(getters.retailTopWinsData.daily[0], getters.stashedRetailTopWinsData?.daily[0]);

      if (isNewTopBetshop) {
        topBetshopSwap();
        setTimeout(() => {
          commit(types.MERGE_RETAIL_TOP_WINS);
        }, 1000);
      } else {
        commit(types.MERGE_RETAIL_TOP_WINS);
      }
    }
  },
  newTicket({ state, commit }, payload) {
    commit(types.POPULATE_STREAM, payload);

    if (state.ticketStream.length === 1 && state.retailFeedData) {
      commit(types.UPDATE_RETAIL_FEED);
    }
  },
  switchTopWinsTab({
    state,
    commit,
    getters,
    dispatch,
  }) {
    const tabIndex = state.topWinsTabs.indexOf(state.currentTopWinsTab);
    const newTab = !state.currentTopWinsTab || tabIndex === state.topWinsTabs.length - 1
      ? state.topWinsTabs[0]
      : state.topWinsTabs[tabIndex + 1];

    commit(types.SET_TOP_WINS_TAB, newTab);
    if ((newTab === 'weekly' || newTab === 'monthly') && getters.isTopWinsRefreshCycle) {
      dispatch('getRetailTopWins');
    }
  },
  resetRefreshCycle({ commit }) {
    commit(types.SET_TOP_WINS_REFRESH_CYCLE, true);
  },
  setTopWinsRefresh({ commit }, payload) {
    commit(types.SET_TOP_WINS_REFRESH, payload);
  },
  setPusherState({ commit }, payload) {
    commit(types.SET_PUSHER_STATE, payload);
  },
  setLoadingMessage({ commit }, payload) {
    commit(types.SET_LOADING_MESSAGE, payload);
  },
  setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
  },
  setUnavailable({ commit }) {
    commit(types.SET_LOADING, true);
    commit(types.SET_LOADING_MESSAGE, 'retailConnecting');
    commit(types.SET_UNAVAILABLE, true);
  },
  async removeUnavailable({ commit, dispatch }) {
    commit(types.CLEAR_STREAM);
    await dispatch('getRetailFeed');
    commit(types.SET_UNAVAILABLE, false);
    commit(types.SET_LOADING, false);
  },
  removeRetailTicket({ commit }, payload) {
    commit(types.REMOVE_RETAIL_TICKET, payload);
  },
  setRetailBaseFontSize({ commit }) {
    const { width, height } = window.screen;
    let baseFontSize;

    switch (true) {
      case width <= 1280:
        baseFontSize = 6;
        break;
      case width <= 1366:
        baseFontSize = 7;
        break;
      case width < 1600:
        baseFontSize = 8;
        break;
      case width < 2560:
        baseFontSize = 10;
        break;
      case width < 3840:
        baseFontSize = 15;
        break;
      case width >= 3840:
        baseFontSize = 20;
        break;
      default:
        baseFontSize = 10;
    }
    // tickets containers height = screen height - 19em / 47em
    const feedTicketsContainerHeight = height - 19 * baseFontSize;
    const topWinsTicketsContainerHeight = height - 47 * baseFontSize;

    // number of tickets = available space - (ticket height + gap)
    const feedSize = Math.ceil(feedTicketsContainerHeight / (6 * baseFontSize)) + 1;
    const topWinsSize = Math.ceil(topWinsTicketsContainerHeight / (5 * baseFontSize));

    commit(types.SET_RETAIL_BASE_FONT_SIZE, baseFontSize);
    commit(types.SET_NUMBER_OF_TICKETS, { feedSize, topWinsSize });
  },
};
