export default {
  retailFeedData: (state) => state.retailFeedData,
  retailTopWinsData: (state) => state.retailTopWinsData,
  stashedRetailTopWinsData: (state) => state.stashedRetailTopWinsData,
  currentTopWinsTab: (state) => state.currentTopWinsTab,
  topWinsRefreshAvailable: (state) => state.topWinsRefreshAvailable,
  topWinsSize: (state) => state.topWinsSize || 12,
  feedSize: (state) => state.feedSize || 16,
  isLoading: (state) => state.loading,
  loadingMessage: (state) => state.loadingMessage,
  pusherState: (state) => state.pusherState,
  isUnavailable: (state) => state.retailUnavailable,
  isRPi: (state, getters, rootState) => rootState.config.quality === 'rpi',
  baseFontSize: (state) => state.baseFontSize,
  computedBaseFontSize: (state) => `${state.baseFontSize}px`,
  isLoadFailure: (state) => state.loadFailure,
  isTopWinsRefreshCycle: (state) => state.topWinsRefreshCycle,
};
