import { createApp } from 'vue';
import { find } from 'lodash';
import App from './App';
import router from './router';
import store from './store';
import LocalizedLabel from './components/LocalizedLabel';
import ImageHandler from './components/ImageHandler';
import i18n from './i18n';
import { getConfiguration } from './utility';

(async () => {
  const config = getConfiguration();
  const translations = (await i18n.boost(config.language)).default;
  const path = window.location.pathname === '/' ? window.location.pathname : window.location.pathname.replace(/\/+$/, '');
  const route = find(router.options.routes, { path });
  if (route) {
    config.route = route?.name;
  } else {
    // eslint-disable-next-line
    console.error(`[BOOST]: ${window.location.pathname} is not valid boost route`);
  }
  store.state.translations = translations;
  store.state.config = config;

  const app = createApp(App)
    .use(router)
    .use(store)
    .use(LocalizedLabel)
    .component('ImageHandler', ImageHandler)
    .mount('#app');

  if (window.Cypress) {
    window.app = app;
  }
})();
