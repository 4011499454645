export const localStorageGet = (item) => localStorage.getItem(item);

export const localStorageSet = (item, value) => localStorage.setItem(item, value);

export const localStorageGetAndSet = (item, value) => {
  if (!localStorageGet(item)) {
    localStorageSet(item, value);
    return value;
  }
  return localStorageGet(item);
};
