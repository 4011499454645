import { lowerFirst, isObject, map } from 'lodash';

export default function getAnimationType(values, newValue, oldValue) {
  const formattedValues = isObject(values[0])
    ? map(values, (val) => lowerFirst(val.value))
    : values;

  if (formattedValues.includes(lowerFirst(newValue))) {
    return formattedValues.indexOf(lowerFirst(newValue)) < formattedValues.indexOf(lowerFirst(oldValue)) ? 'fade-right' : 'fade-left';
  }
  return '';
}
